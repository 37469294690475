import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import {
  getCohortUids,
  getProjectObjectCompletedByCohortUser,
  fetchUserDataByUid,
  getDemoDayProjectObjectCompletedByCohortUser
} from "api/db";

import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import { SmallPill } from "components/Post/styles";
import { DashboardContainer, Buttons } from "components/Admin/styles";
import Label from "components/Elements/Label";

import Button from "components/Elements/Button";
import {
  addTagToCohortByEmail,
  removeTagByEmail,
  fetchActiveCohort
} from "../../api/db";

const TagInput = styled.input`
  &:focus {
    border: 1px solid #666;
    outline: none;
  }
  border: 1px solid #999;
  width: 20%;
  margin: 0 auto;
  display: block;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

export const EmailInput = styled(TextareaAutosize)`
  resize: none;
  height: 40px;
  font-size: 12px;
  border: 1px solid #aaa;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 50%;
  margin: 0 auto;
  display: block;
  padding: 10px;
  border-radius: 5px;
  &:focus {
    border: 1px solid #666;
    outline: none;
  }
`;

export default function Completions({ location }) {
  const [cohortCompletionData, setCohortCompletionData] = useState([]);
  const [cohortTag, setCohortTag] = useState("");
  const [emailsString, setEmailsString] = useState("");
  const [tag, setTag] = useState("");
  const [tagToRemove, setTagToRemove] = useState("");
  const [emailsToRemove, setEmailsToRemove] = useState("");
  const [totalMembers, setTotalMembers] = useState(null);
  const [completionTotal, setCompletionTotal] = useState({
    one: 0,
    two: 0,
    three: 0,
    capstone: 0
  });
  const [activeCohortNum, setActiveCohortNum] = useState(3);

  function addTagClickHandler() {
    if (emailsString != "") {
      let emails = emailsString.split(",");
      for (let i = 0; i < emails.length; i++) {
        // trim each email so there's no whitespace on either side
        emails[i] = emails[i].trim();
      }
      if (tag != "") {
        addTagToCohortByEmail(emails, tag.trim());
      } else {
        alert("Please fill out the tag");
      }
    } else {
      alert("Please enter some emails");
    }
  }

  function removeTagClickHandler() {
    if (emailsToRemove != "") {
      let emails = emailsToRemove.split(",");
      for (let i = 0; i < emails.length; i++) {
        // trim each email so there's no whitespace on either side
        emails[i] = emails[i].trim();
      }
      if (tagToRemove != "") {
        removeTagByEmail(emails, tagToRemove);
      } else {
        alert("Please fill out the tag");
      }
    } else {
      alert("Please enter some emails");
    }
  }

  useEffect(() => {
    let cohortNumber = location.pathname.slice(14);
    let cohort = "";
    if (cohortNumber != "") {
      cohort = "cohort " + cohortNumber;
      setCohortTag(cohort);
    }

    fetchActiveCohort().once("value", snapshot => {
      setActiveCohortNum(snapshot.val());
    });

    if (cohort != "") {
      getCohortUids(cohort).once("value", snapshot => {
        let uids = snapshot.val();
        setTotalMembers(uids.length);
        uids.map((uid, index) => {
          fetchUserDataByUid(uid).once("value", snapshot => {
            let userData = snapshot.val();
            getProjectObjectCompletedByCohortUser(uid).once(
              "value",
              snapshot => {
                let submittedProjects = snapshot.val()
                  ? Object.values(Object.values(snapshot.val()))
                  : null;
                if (submittedProjects) {
                  submittedProjects = submittedProjects.filter(
                    IBuiltThis =>
                      IBuiltThis.tags && IBuiltThis.tags.includes(cohort)
                  );

                  let newCompletionTotal = completionTotal;

                  if (submittedProjects[0]) {
                    newCompletionTotal.one += 1;
                  }
                  if (submittedProjects[1]) {
                    newCompletionTotal.two += 1;
                  }
                  if (submittedProjects[2]) {
                    newCompletionTotal.three += 1;
                  }

                  setCompletionTotal(newCompletionTotal);

                  getDemoDayProjectObjectCompletedByCohortUser(uid).once(
                    "value",
                    snapshot => {
                      let capstone = snapshot.val()
                        ? Object.values(snapshot.val())
                        : null;
                      if (capstone && capstone != undefined) {
                        capstone = capstone.filter(
                          CustomIBuiltThis =>
                            CustomIBuiltThis.tags &&
                            CustomIBuiltThis.tags.includes(cohort)
                        );

                        let newCompletionCapstoneTotal = completionTotal;
                        if (capstone[0]) {
                          newCompletionCapstoneTotal.capstone += 1;
                        }

                        setCompletionTotal(newCompletionCapstoneTotal);
                      }

                      const object = {
                        ...userData,
                        capstone: [],
                        projects: []
                      };
                      object.projects.push(submittedProjects);
                      object.capstone.push(capstone);
                      setCohortCompletionData(completions =>
                        completions.concat(object)
                      );
                    }
                  );
                } else {
                  const object = {
                    ...userData
                  };
                  setCohortCompletionData(completions =>
                    completions.concat(object)
                  );
                }
              }
            );
          });
        });
      });
    }
  }, [location]);

  return (
    <DashboardContainer>
      <Label>
        {" "}
        <a href="/admin/dashboard"> &larr; Back</a>{" "}
      </Label>
      <h1>{cohortTag} project completions</h1>
      <Buttons>
        <Link
          style={{ maxWidth: "30em", margin: "10px auto" }}
          to={activeCohortNum && `/admin/cohort-metrics/${activeCohortNum}`}
        >
          Metrics
        </Link>
      </Buttons>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>profile</th>
            <th>username</th>
            <th>email</th>
            <th>project 1</th>
            <th>project 2</th>
            <th>project 3</th>
            <th>capstone</th>
          </tr>
        </thead>
        <tbody>
          {cohortCompletionData &&
            cohortCompletionData.map((learner, index) => {
              return (
                <tr key={learner.email_address}>
                  <td>{index}</td>
                  <td>
                    <img
                      src={learner && learner.photoURL && learner.photoURL}
                      height="40px"
                    ></img>
                  </td>
                  <td>@{learner && learner.id}</td>
                  <td>{learner && learner.email_address}</td>
                  <td>
                    {learner &&
                      learner.projects &&
                      learner.projects[0] &&
                      learner.projects[0][0] &&
                      learner.projects[0][0].title}
                  </td>
                  <td>
                    {learner &&
                      learner.projects &&
                      learner.projects[0] &&
                      learner.projects[0][1] &&
                      learner.projects[0][1].title}
                  </td>
                  <td>
                    {learner &&
                      learner.projects &&
                      learner.projects[0][2] &&
                      learner.projects[0][2].title}
                  </td>
                  <td>
                    {learner &&
                      learner.capstone &&
                      learner.capstone[0] &&
                      learner.capstone[0][0] &&
                      learner.capstone[0][0].title}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>project 1 completion rate</th>
            <th>project 2 completion rate</th>
            <th>project 3 completion rate</th>
            <th>cohort completion rate </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {completionTotal.one}/{totalMembers} (
              {((completionTotal.one / totalMembers) * 100).toFixed(2)}%)
            </td>
            <td>
              {completionTotal.two}/{totalMembers} (
              {((completionTotal.two / totalMembers) * 100).toFixed(2)}%)
            </td>
            <td>
              {completionTotal.three}/{totalMembers} (
              {((completionTotal.three / totalMembers) * 100).toFixed(2)}%)
            </td>
            <td>
              {completionTotal.capstone}/{totalMembers} (
              {((completionTotal.capstone / totalMembers) * 100).toFixed(2)}%)
            </td>
          </tr>
        </tbody>
      </table>

      <h1>add new users to cohort</h1>
      <p>this will add the user to roster and give them the cohort tag</p>
      <TagInput
        onChange={e => {
          setTag(e.target.value);
        }}
        value={tag}
        placeholder="tag"
      ></TagInput>
      <EmailInput
        onChange={e => {
          setEmailsString(e.target.value);
        }}
        value={emailsString}
        placeholder="emails delimited by a comma, no space"
      ></EmailInput>
      <Button
        onClick={addTagClickHandler}
        style={{ display: "block", margin: "20px auto" }}
      >
        add tags
      </Button>
      <br />
      <br />
      <h1>remove tag from users</h1>
      <p>this will only remove the cohort tag from a user, not the roster</p>
      <TagInput
        onChange={e => {
          setTagToRemove(e.target.value);
        }}
        value={tagToRemove}
        placeholder="tag to remove"
      ></TagInput>
      <EmailInput
        onChange={e => {
          setEmailsToRemove(e.target.value);
        }}
        value={emailsToRemove}
        placeholder="emails delimited by a comma, no space"
      ></EmailInput>
      <Button
        onClick={removeTagClickHandler}
        style={{ display: "block", margin: "20px auto" }}
      >
        remove tag
      </Button>
    </DashboardContainer>
  );
}
